import createApiClient from "@tt/vue-components/src/services/apiClient";
import baseApiService from "@tt/vue-components/src/services/baseApiService";
import EnvProvider from "@tt/vue-environment/EnvProvider";
import apiServiceHelpers from "@tt/vue-components/src/services/apiServiceHelpers";

const apiClient = createApiClient(EnvProvider.value("ORDER_API_URL"));

const orderService = {
  orders: {
    ...baseApiService(apiClient, 'orders'),
    validationRun: ({data}) => apiClient.post("validation_run", data, {
      headers: {"Content-Type": "application/json"}
    })
  },
  deliveryMethods:{...baseApiService(apiClient,'delivery_method')},
  sources: {...baseApiService(apiClient,'sources')},
  orderItems: {...baseApiService(apiClient,'order_items')},
  orderItemStates: {...baseApiService(apiClient, 'order_item_states')},
  addresses: {...baseApiService(apiClient,'addresses')},
  filters: {...baseApiService(apiClient,'filters')},
  orderShipments: {...baseApiService(apiClient,'order_shipments')},
  orderShipmentSearch: {...baseApiService(apiClient,'order_shipments:quick-search')},
  orderReturns: {...baseApiService(apiClient,'order_returns')},
  suborder: {...baseApiService(apiClient,'order_suborders')},
  orderComments: {...baseApiService(apiClient,'comments')},
  orderImports: {...baseApiService(apiClient,'order_imports')},
  reasons: {...baseApiService(apiClient,'reasons')},
  orderCancels: {...baseApiService(apiClient,'order_cancels')},
  settings: {
    settingsByGroup: (group, params) => apiClient.get(`settings:group/${group}` + apiServiceHelpers.buildQueryParams(params), {
      headers: {"Content-Type": "application/json"}
    })
  },
}
export default orderService;

