import { tableState, tableMutations, tableActions } from "@tt/vue-components";
import orderService from "@/services/OrderService";

const deliveryMethodsOverview = {
  namespaced: true,
  state: {
    ...tableState,
    deliveryMethod: null,
  },
  mutations: {
    ...tableMutations,
    SET_DELIVERY_METHOD(state, deliveryMethod) {
      state.deliveryMethod = deliveryMethod;
    }
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_LOADING", true);
        const params = { pagination: context.state.pagination, sorting: context.state.sorting, search: context.state.search };
        orderService.deliveryMethods.list(params)
          .then(json => {
            context.commit("SET_ITEMS", json);

            // preselect
            if (context.state.source) {
              json.items.forEach(source => {
                if (source.id === context.state.source.id) {
                  context.dispatch("selectSource", source);
                }
              });
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            context.commit("SET_LOADING", false);
          });
      }
    }
  }
};

export default deliveryMethodsOverview;
