import { tableActions } from "@tt/vue-components";
import entityService from "@/services/EntityService";

const entityAssetsList = {
  namespaced: true,
  state: {
    assets: []
  },
  mutations: {
    SET_ASSETS(state, skuAssets) {
      state.assets.push(skuAssets);
    }
  },
  actions: {
    ...tableActions,
    fetchAssets({state, commit}, sku = "") {
      if (!state.assets.find(item => item.sku === sku)) {
        entityService.skus.getSubResourceAlt( "assets", sku)
            .then(assets => {
              commit("SET_ASSETS", {sku: sku, assets: assets});
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {
            });
      }
    },
  },
  getters: {
   firstImage: (state) => (sku, size) => {
      const skuAssets = state.assets.find(item => item.sku === sku)
      if (skuAssets && skuAssets.assets) {
        return skuAssets.assets["firstImage"][size]
      }
    }
  }
};

export default entityAssetsList;
