import createClient from "@tt/vue-components/src/services/apiClient";
import EnvProvider from "@tt/vue-environment/EnvProvider";
import baseApiService from "@tt/vue-components/src/services/baseApiService";
const apiClient = createClient(EnvProvider.value("ENTITY_API_URL"));

const EntityService = {
  locations: {
    ...baseApiService(apiClient, 'locations'),
  },
  skus: {
    ...baseApiService(apiClient, 'skus'),
  }
}
export default EntityService;
