import orderService from "@/services/OrderService";
import {tableMutations} from "@tt/vue-components";

const validationRuns = {
    namespaced: true,
    state: {
        loading: false,
        items: [],
        sorting: { sortBy: ["createdDt"], sortDesc: [true] },
    },
    mutations: {
        ...tableMutations,
        SET_ITEMS(state, items) {
            state.items = items
        }
    },
    actions: {
        fetchItems({state, rootState, commit}, refresh = false) {
            if (refresh || state.items.length === 0) {
                const reference = rootState.orderImportsOverview.item?.id
                const params = { sorting: state.sorting};
                if (reference) {
                    orderService.orderImports.getSubResource(reference, 'runs', params)
                        .then(json => {
                            if (json) {
                                commit("SET_ITEMS", json.items)
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                        .finally(() => {
                            commit("SET_LOADING", false)
                        })
                }
            }
        },
        resetItems({commit}) {
            commit("SET_ITEMS", []);
        }
    }
};

export default validationRuns;
