import orderService from "@/services/OrderService";

const validationRun = {
  namespaced: true,
  state: {
    planId: null,
    plan: null,
    plans: [],
    skipRules: [],
    dryRun: false,
    validationRuns: []
  },
  mutations: {
    SET_PLAN_ID(state, planId) {
      state.planId = planId;
    },
    SET_PLAN(state, plan) {
      state.plan = plan;
    },
    SET_PLANS(state, plans) {
      state.plans = plans;
    },
    SET_SKIP_RULES(state, skipRules) {
      state.skipRules = skipRules;
    },
    SET_VALIDATION_RUNS(state, validationRuns) {
      state.validationRuns = validationRuns;
    }
  },
  actions: {
    fetchValidationPlans(context, refresh) {
      if (refresh || context.state.plans.length === 0) {
        const params = { pagination: { page: 1, itemsPerPage: 1000 } };
        orderService.validationPlans.list(params)
          .then(json => {
            context.commit("SET_PLANS", json.items);

            // set plan if order has plan
            if (context.state.planId === null) {
              let plan = context.rootState.ordersOverview.order.source.plan;
              if (plan) {
                context.state.planId = plan.id;
                context.dispatch("fetchPlan");
              }
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    fetchPlan(context) {
      orderService.validationPlans.get(context.state.planId)
        .then(json => {
          context.commit("SET_PLAN", json);
        })
        .catch(err => {
          console.log(err);
        });
    },
    rerun(context) {
      return new Promise((resolve, reject) => {
        orderService.orders.validationRun({
          order: "/orders/" + context.rootState.ordersOverview.order.id,
          plan: "/validation/plans/" + context.state.planId,
          skipRules: context.state.skipRules,
          dryRun: false
        })
          .then(json => {
            // refresh order
            context.dispatch(
              "ordersOverview/order/refreshValidationRuns",
              null,
              {
                root: true
              }
            );
            context.dispatch("ordersOverview/order/refreshOrder", null, {
              root: true
            });
            resolve(json);
          })
          .catch(err => {
            console.log(err);
            reject(err["hydra:description"]);
          });
      });
    },
    fetchValidationRuns(context) {
      if (context.rootState.ordersOverview.order !== null) {
        const params = {
          pagination: { page: 1, itemsPerPage: 1000 },
          sorting: { sortBy: ["createdDt"], sortDesc: [false] },
          search:  [{
              column: "order.id",
              value: context.rootState.ordersOverview.order["id"]
            }]
        };
        orderService.validationRuns.list(params)
          .then(json => {
            context.commit("SET_VALIDATION_RUNS", json.items);
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    refreshValidationRuns(context) {
      if (context.rootState.ordersOverview.order !== null) {
        context.dispatch(
          "fetchValidationRuns",
          context.rootState.ordersOverview.order
        );
      }
    }
  },
  getters: {
    planList: state => {
      return state.plans.map(function(plan) {
        return {
          text: plan.name,
          value: plan.id,
          description: plan.description
        };
      });
    }
  }
};

export default validationRun;
