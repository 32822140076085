import createApiClient from "@tt/vue-components/src/services/apiClient";
import baseApiService from "@tt/vue-components/src/services/baseApiService";
import EnvProvider from "@tt/vue-environment/EnvProvider";

const apiClient = createApiClient(EnvProvider.value("ALLOCATION_API_URL"));

const allocationService = {
  requests: {
    ...baseApiService(apiClient, 'requests'),
  },
  requestItems: {
    ...baseApiService(apiClient, 'request_items')
  },
  tickets: {
    ...baseApiService(apiClient, 'tickets')
  }
}
export default allocationService;

