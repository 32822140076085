import Vue from "vue";
import VCurrencyField from "v-currency-field";
import { VTextField } from "vuetify/lib";

Vue.component("v-text-field", VTextField);
Vue.use(VCurrencyField, {
  locale: "nl-NL",
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0
});
