import orderService from "@/services/OrderService";
import {tableMutations} from "@tt/vue-components";

const reasons = {
  namespaced: true,
  state: {
    loading: false,
    reasons: [],
  },
  mutations: {
    ...tableMutations,
    SET_REASONS(state, reasons) {
      state.reasons = reasons;
    }
  },
  actions: {
    fetchReasons(context, type, refresh = false) {
      if (context.state.reasons.length === 0 || refresh === true) {
        orderService.reasons.list()
            .then(json => {
              context.commit("SET_REASONS", json.items);
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {
              context.commit("SET_LOADING", false);
            });
      }
    },
  },
  getters: {
    reasonById: (state) => (id) => {
      return state.reasons.find(item => item['@id'] === id)?.name
    }
  }
};

export default reasons;
