import orderService from "@/services/OrderService";

const channelFilter = {
  namespaced: true,
  state: {
    data: [],
    options: []
  },
  mutations: {
    SET_OPTIONS(state, options) {
      state.options = options;
    },
    SET_DATA(state, data) {
      state.data = data;
    }
  },
  actions: {
    getOptions(context, refresh) {
      if (refresh || context.state.options.length === 0) {
        const params = { pagination: {page: 1, itemsPerPage: 1000 }, sorting: { sortBy: ["name"], sortDesc: [false]} };
        orderService.sources.list(params)
          .then(json => {
            context.commit("SET_OPTIONS", json.items);
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    setData(context, data) {
      context.commit("SET_DATA", data);
    }
  },
  getters: {
    data: state => {
      return state.options;
    },
    options: state => {
      return state.options.map(option => {
        return {
          text: option.name,
          value: option.code,
          icon: option.icon
        };
      });
    },
    toParams: state => {
      return state.data.map(option => {
        return { column: "source.code[]", value: option.value };
      });
    }
  }
};

export default channelFilter;
