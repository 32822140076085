import orderService from "@/services/OrderService";

const flags = {
  namespaced: true,
  state: {
    flags: [],
    search: [
      { column: 'cache', value: false}
    ]
  },
  modules: {
  },
  mutations: {
    SET_FLAGS(state, flags) {
      state.flags = flags
    }
  },
  actions: {
    load(context, refresh = false) {
      if (refresh || context.state.flags.length === 0) {
        const params = { search: context.state.search };

        orderService.settings.settingsByGroup('feature', params)
            .then(json => {
              if (json) {
                context.commit("SET_FLAGS", json)
              }
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => {
            });
      }
    },
  },
  getters: {
    hasFeature: (state) => (name) => {
      return (state.flags[name] === 'true')
    }
  }
};

export default flags;
