const orderDateFilter = {
  namespaced: true,
  state: {
    data: []
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    }
  },
  actions: {
    setData(context, value) {
      context.commit("SET_DATA", value);
    }
  },
  getters: {
    data: state => {
      return state.data;
    },
    toParams: state => {
        if (state.data[0] === state.data[1] && state.data[0]) {
          let date = new Date(state.data[1]);
          date.setDate(date.getDate() +1);

          return [{ column: "orderDt[after]", value: state.data[0]}, { column: "orderDt[strictly_before]", value: date.toISOString().substring(0, 10)}];
        } else {
          return [{ column: "orderDt[after]", value: state.data[0]}, { column: "orderDt[before]", value: state.data[1]}];
        }
    }
  }
};

export default orderDateFilter;
