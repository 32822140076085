import Vue from "vue";
import i18n from "@/plugins/i18n";
import persistantLanguage from "@tt/persistant-language";

const domain = (self.origin.includes('localhost')) ? 'localhost' : self.origin.match(/[^.]*\.[^.]*.[^.]*$/)[0]

Vue.use(persistantLanguage, {
  fallbackLanguage: "en",
  expire: "Fri, 31 Dec 9999 23:59:59 GMT",
  domain: domain,
  i18n
});