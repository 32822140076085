import i18n from "@/plugins/i18n";

const commentStatusFilter = {
    namespaced: true,
    state: {
        data: [],
        options: []
    },
    mutations: {
        SET_OPTIONS(state, options) {
            state.options = options;
        },
        SET_DATA(state, data) {
            state.data = data;
        }
    },
    actions: {
        getOptions(context) {
            context.commit("SET_OPTIONS", [
                {
                    name: i18n.t("order.filter.status.open.label"),
                    code: 'open'
                },
                {
                    name: i18n.t('order.filter.status.resolved.label'),
                    code: 'resolved'
                }
            ]);
        },
        setData(context, data) {
            context.commit("SET_DATA", data);
        }
    },
    getters: {
        data: state => {
            return state.options;
        },
        options: state => {
            return state.options.map(option => {
                return {
                    text: option.name,
                    value: option.code,
                    icon: ''
                };
            });
        },
        toParams: state => {
            return state.data.map(option => {
                return { column: "comments.status[]", value: option.value };
            });
        }
    }
};

export default commentStatusFilter;
