const shippingStatusFilter = {
    namespaced: true,
    state: {
        data: ""
    },
    mutations: {
        SET_DATA(state, orderNo) {
            state.data = orderNo;
        }
    },
    actions: {
        setData(context, value) {
            context.commit("SET_DATA", value);
        }
    },
    getters: {
        data: state => {
            return state.data;
        },
        toParams: state => {
            return [{ column: "shipmentStatus", value: state.data }];
        }
    }
};

export default shippingStatusFilter;
