import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";

import orderNoFilter from "@/store/modules/pickupOrdersOverview/filter/orderNoFilter";
import shipmentReferenceFilter from "@/store/modules/pickupOrdersOverview/filter/shipmentReferenceFilter";
import shippingMethodFilter from "@/store/modules/pickupOrdersOverview/filter/shippingMethodFilter";
import isAnnouncedFilter from "@/store/modules/pickupOrdersOverview/filter/isAnnouncedFilter";
import isDeliveredFilter from "@/store/modules/pickupOrdersOverview/filter/isDeliveredFilter";
import isShippedFilter from "@/store/modules/pickupOrdersOverview/filter/isShippedFilter";
import locationsFilter from "@/store/modules/pickupOrdersOverview/filter/locationsFilter";
import orderService from "@/services/OrderService";

const pickupOrdersOverview = {
  requestController: null,
  namespaced: true,
  state: {
    ...tableState,
    item: null,
    sorting: { sortBy: ["order.orderDt"], sortDesc: [true] },
    pagination: { itemsPerPage: 25, itemsPage: 1 },
    filters: [],
    defaultFilters: []
  },
  modules: {
    shippingMethodFilter,
    orderNoFilter,
    isAnnouncedFilter,
    isShippedFilter,
    isDeliveredFilter,
    locationsFilter,
    shipmentReferenceFilter
  },
  mutations: {
    ...tableMutations,
    SET_ITEM(state, item) {
      state.item = item;
    }
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_LOADING", true);
        const params = { pagination: context.state.pagination, sorting: context.state.sorting, search: context.state.search };

        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();
        orderService.orderShipmentSearch.list(params, { signal: this.requestController.signal })
          .then(json => {
            if (json) {
              context.commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            context.commit("SET_LOADING", false);
          });
      }
    },
    search(context) {
      const params = [];
      const defaultParams = [];

      if (context.state.defaultFilters.length || context.state.filters.length) {
        context.state.defaultFilters.forEach(filter => {
          let filterParams = context.getters[filter.name + "/toParams"];
          if (filterParams !== undefined && filterParams.length > 0) {
            for (let i = 0; i < filterParams.length; i++) {
              defaultParams.push(JSON.parse(JSON.stringify(filterParams[i])));
            }
          }
        });
        context.state.filters.forEach(filter => {
          let filterParams = context.getters[filter.name + "/toParams"];
          if (filterParams !== undefined && filterParams.length > 0) {
            for (let i = 0; i < filterParams.length; i++) {
              params.push(JSON.parse(JSON.stringify(filterParams[i])));
            }
          }
        });
      }
      const allParams = [...defaultParams, ...params]
      if (allParams.length) {
        context.commit("SET_SEARCH", allParams.filter(param => {
          return param.value !== undefined && param.value !== null && param.value !== "" && param.value !== []
        }));
      }
      context.dispatch("fetchItems", true);
    },
    selectItem(context, item) {
      context.commit("SET_ITEM", item);
      if (item) {
        context.dispatch("ordersOverview/order/fetchOrder", item.order.id, {root: true});
      }

      if (item !== null && item !== undefined) {
        context.dispatch("ordersOverview/shipments/fetchItems", item.order.id, {root: true});
        context.dispatch("ordersOverview/requestItems/fetchItems", item.order.reference, {root: true});
        context.dispatch("ordersOverview/orderReturns/fetchItems", item.order.id, {root: true});
      } else {
        context.dispatch("ordersOverview/shipments/resetItems", null, {root: true});
        context.dispatch("ordersOverview/requestItems/resetItems", null, {root: true});
        context.dispatch("ordersOverview/orderReturns/resetItems", null, {root: true});
      }
    },
    edit({commit}, item) {
      return new Promise((resolve, reject) => {
        orderService.orderShipments.update(item['id'], item)
          .then((data) => {
            commit("EDIT_ITEM", data);
            commit("SET_ITEM", data);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    }
  },
  getters: {
    ...tableGetters
  }
};

export default pickupOrdersOverview;
